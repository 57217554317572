import { Injectable } from '@angular/core';
import { StorageLibService } from '../storage/storage-lib.service';
import { Router } from '@angular/router';
import { MemberToken, SignInMobileRequest, SignUpMobileRequest } from '../../libs/proto/mobile_pb';
import { MemberTokenConversionService } from '../conversion/signin/member-token-conversion.service';
import { Customer, Language, MobileApp, MemberProfileSettings, Group, MenuAlias, Member, MemberType } from '../../libs/proto/commUnity_pb';
import { TranslateService } from '@ngx-translate/core';
import { SigninReqConversionService } from '../conversion/signin/signin-req-conversion.service';
import { communityAppType } from '../../config/type';
import { MemberProfileSettingConversionService } from '../conversion/member/member-profile-setting-conversion.service';
import { CustomerConversionService } from '../conversion/customer/customer-conversion.service';
import { GroupConversionService } from '../conversion/group/group-conversion.service';
import { GrpcAdsLibService } from '../grpc/ads/grpc-ads-lib.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DeviceLibService } from '../device/device-lib.service';
import { Key } from 'protractor';
import { MemberTypeConversionService } from '../conversion/member_type/member-conversion.service';

interface ProductsPageCache {
  collapsed?: string[];
  id?: string;
}
interface DocumentsPageCache {
  collapsed?: string[];
  id?: string;
}
interface RestaurantPageCache {
  collapsed?: string[];
  id?: string;
}
interface PostsPageCache{
  collapsed?: string[];
  id?: string;
}

interface UserLibServiceData {
  login?: SignInMobileRequest;
  token?: MemberToken;
  lg?: string;

  profileSettings?: MemberProfileSettings;
  memberSettings?: MemberProfileSettings;
  /**
   * temporary customer for come back from disclaim
   */
  selectedCustomer?: Customer;
  selectedGroup?: Group;
  /**
   * user just signout, value will be reset when refresh/reload
   */
  signOut?: boolean;
  producstPage?: ProductsPageCache;
  postsPage?: PostsPageCache;
  restaurantPage?: RestaurantPageCache;
  noContinue?: boolean;
  documentsPage?: DocumentsPageCache;

  menuAlias?: MenuAlias;
}

@Injectable({
  providedIn: 'root'
})
export class UserLibService {

  private user: UserLibServiceData;
  /**
   * temporary token for disclaimer
   */
  disclaimerToken?: MemberToken;
  disclaimerCallbackURL?: string;

  constructor(
    private route: Router,
    private tokenConv: MemberTokenConversionService,
    private logConv: SigninReqConversionService,
    private storageLib: StorageLibService,
    private setConv: MemberProfileSettingConversionService,
    private cusConv: CustomerConversionService,
    private grpConv: GroupConversionService,
    private detector: DeviceLibService,
    private translate: TranslateService,
    private convLib: MemberTypeConversionService,

  ) {
    this.user = {};
    this.logConv.FromStorage(this.storageLib.get('login'), v => {
      this.user.login = v;
    });
    this.tokenConv.FromStorage(this.storageLib.get('user'), v => {
      if (v === null) {
        this.storageLib.set('user', null);
        // reset only once
        if (this.storageLib.get('reset-me') !== 'Y') {
           this.storageLib.set('reset-me', 'Y');
           location.reload();
           return;
        }
      }
      this.user.token = v;
      // if ok reset flag
      this.storageLib.set('reset-me', null);
    });
    this.setConv.FromStorage(this.storageLib.get('p.set'), v => {
      if (v === null) { return; }
      this.user.profileSettings = v;
    });
    this.setConv.FromStorage(this.storageLib.get('mem.set'), v => {
      if (v === null) { return; }
      // this.user.memberSettings = v;
    });
    if (this.user.token?.getToken()) {
      this.cusConv.FromStorage(this.storageLib.get('customer'), v => {
        if (v === null) { return; }
        this.user.selectedCustomer  = v;
      });

      this.grpConv.FromStorage(this.storageLib.get('group'), v => {
        if (v === null) { return; }
        this.user.selectedGroup  = v;
      });
    }
  }

  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // tslint:disable-next-line: no-bitwise
      const r = Math.random() * 16 | 0;
      // tslint:disable-next-line: no-bitwise
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  /**
   * after called signin
   * - updateUser : keep profile, token in storage
   */
  private afterSignIn(mobileToken: MemberToken, guest?: boolean) {

    this.updateUser(mobileToken, guest);
  }
  /**
   * update user in storage
   * @param mobileToken mobile token
   * @param guest if guest, don't save login
   */
  updateUser(mobileToken: MemberToken, guest?: boolean) {
    if (!(guest || false)) {
      this.storageLib.set('login', this.logConv.ToStorage( this.user.login) );

      // keep login-customer
      this.storageLib.set('login-' + (this.Data.selectedCustomer?.getId() || ''),
              this.logConv.ToStorage( this.user.login) );
    }
    // reset menu alias
    this.storageLib.set('menua', null);
    this.storageLib.set('user', this.tokenConv.ToStorage( mobileToken) );
    this.user.token = mobileToken;

    this.updateUserLang(mobileToken.getProfile());
  }
  /**
   * update current user langauge
   * @param u member
   */
  updateUserLang(u: Member) {
    let lang = this.detector.lang(location.href);
    Object.keys(Language).map( lg => {
      if (u.getLanguage() == Language[lg]) {
          lang = lg.toLowerCase();
      }
    });

    if (this.user.lg !== lang) {
      this.user.lg = lang;
      this.translate.use(lang);
    }
  }
  getUserCustomer(callback: (v: SignInMobileRequest) => void) {
    this.logConv.FromStorage(
        this.storageLib.get('login-' + (this.Data.selectedCustomer?.getId() || ''))
    , callback);
  }
  /**
   * update this.Data.token in storage
   */
  updateUserData() {
    this.storageLib.set('user', this.tokenConv.ToStorage( this.Data.token ) );
  }

  get Data(): UserLibServiceData {
    return this.user;
  }
  /**
   * keep user in userservice (not storage)
   */
  setUser(u: string, p: string) {
    this.user.login = new SignInMobileRequest();
    this.user.login.setUsername(u);
    this.user.login.setPassword(p);
  }
  /**
   * clear mobiletoken
   */
  clear(){
    this.storageLib.set('login', null);
    this.storageLib.set('user', null);
    this.storageLib.set('customer', null);
    this.storageLib.set('cart', null);
    this.storageLib.set('cart-badge', null);
    this.storageLib.set('forum-badge', null);
    this.storageLib.set('meal', null);
    this.storageLib.set('meal-id', null);
    this.storageLib.set('meal-badge', null);
    this.storageLib.set('meal-pay-id', null);
    this.storageLib.set('pay-id', null);
    this.storageLib.set('product', null);
    this.storageLib.set('shop-setting', null);
    this.storageLib.set('order', null);
    this.storageLib.set('news', null);
    this.storageLib.set('event', null);
    this.storageLib.set('order-badge', null);
    this.storageLib.set('p.set', null);
    this.storageLib.set('p.set', null);
    this.storageLib.set('album', null);
    this.storageLib.set('benefit', null);
    this.storageLib.set('links', null);
    this.storageLib.set('member', null);
    this.storageLib.set('menu', null);
    this.storageLib.set('menua', null);
    this.storageLib.set('places', null);
    this.storageLib.set('show-ad', null);
    this.Data.profileSettings = undefined;
    this.Data.memberSettings = undefined;
    this.storageLib.cache = {};
  }

  clearGroup(){
    this.storageLib.set('customer', null);
    this.storageLib.set('customers', null);
    this.storageLib.set('group', null);
  }

  /**
   * after click signin
   *
   * - call after signin: updateUser
   * - next action
   *
   * @param v member token
   */
  afterLogin(v: MemberToken, guest?: boolean) {
    this.afterSignIn(v, guest);
    this.nextAction(guest);
  }
  /**
   * next action after login
   *
   */
  private nextAction(guest?: boolean) {

    if (guest) {
      this.route.navigateByUrl('/home');
      return;
    }

    if (!(this.Data.token?.getCustomer().getShowad() || false)) {
      this.beforeGoHomeAfterLogin();
      return;
    }

    // TODO:
    this.beforeGoHomeAfterLogin();
  }
  beforeGoHomeAfterLogin() {
    if (this.Data.noContinue) {
      return;
    }
    if (this.Data.producstPage?.id) {
        const d = this.Data.producstPage?.id;
        if (this.Data.producstPage) { this.Data.producstPage.id = undefined; }
        this.route.navigate(['/product/', d]);
        return;
    }
    if (this.Data.restaurantPage?.id) {
      const d = this.Data.restaurantPage?.id;
      if (this.Data.restaurantPage) { this.Data.restaurantPage.id = undefined; }
      this.route.navigate(['/menu/', d]);
      return;
    }
    if (this.Data.documentsPage?.id) {
      const d = this.Data.documentsPage?.id;
      if (this.Data.documentsPage) { this.Data.documentsPage.id = undefined; }
      this.route.navigate(['/document/', d]);
      return;
    }
    this.route.navigateByUrl('/home');
  }
  /**
   *
   * @param v member token
   * @returns true if goto disclaimer
   */
  checkDisclaimer(v: MemberToken, callback?: () => void): boolean {
    if (v.getProfile().getAcceptdisclaimeron() === 0) {
      let lg = this.detector.applang(v.getProfile().getLanguage());
      this.Data.lg = lg;
      this.translate.use(lg);

      this.disclaimerToken = v;
      this.disclaimerCallbackURL = this.route.url;
      this.route.navigateByUrl('/disclaimer');
      return true;
    }
    return false;
  }
  /**
   * allow only en, fr
   * 
   * translate.setdefaultlang + translate.use
   */
  setPageLanguage(t: TranslateService) {
    let def = this.detector.lang(location.href);  
    
    if ((this.Data.lg || null) == null) {
      this.Data.lg = this.detector.applang(this.Data.token?.getProfile()?.getLanguage());
    }

    t.use( this.Data.lg || def );
  }
  private getRGB(color: string | number[]): number[] {
    let a: RegExpExecArray;
    if ( color && color.constructor === Array && color.length === 3) { return color as number[]; }

    a = /rgb\(\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*\)/.exec(color as string);
    if (a) {
      return[parseInt(a[1], 10), parseInt(a[2], 10), parseInt(a[3], 10)];
    }
    a = /rgb\(\s*([0-9]+(?:\.[0-9]+)?)\%\s*,\s*([0-9]+(?:\.[0-9]+)?)\%\s*,\s*([0-9]+(?:\.[0-9]+)?)\%\s*\)/.exec(color as string);
    if (a) { return[parseFloat(a[1]) * 2.55, parseFloat(a[2]) * 2.55, parseFloat(a[3]) * 2.55]; }

    a = /#([a-fA-F0-9]{2})([a-fA-F0-9]{2})([a-fA-F0-9]{2})/.exec(color as string);
    if (a) { return[parseInt(a[1], 16), parseInt(a[2], 16), parseInt(a[3], 16)]; }

    a = /#([a-fA-F0-9])([a-fA-F0-9])([a-fA-F0-9])/.exec(color as string);
    if (a) { return[parseInt(a[1] + a[1], 16), parseInt(a[2] + a[2], 16), parseInt(a[3] + a[3], 16)]; }

    return [];
  }

  /**
   *
   * @param color hexstring
   * @returns true if dark color
   */
  checkColorDarkness(color: string): boolean {
    if ((color || '') === '') { return true; }

    const rgb = this.getRGB(color);
    if (rgb.length === 0) { return false; }

    const bb = ((0.2126 * rgb[0]) + (0.7152 * rgb[1]) + (0.0722 * rgb[2]));
    return bb < 180.0;
  }
  imageExist(url: string): Promise<string> {
    const img = new Image();
    img.src = url;
    return new Promise<string>( (ok, ko) => {
      img.onerror = () => { ko(); };
      img.onload = () => { ok(url); };
    });
  }

  get designMainBg(): string {
    if (!this.detector.isMobile()) {
      return this.Data.token?.getCustomer()?.getDesktopbackgroundphoto();
    }
    return this.Data.token?.getCustomer()?.getBackgroundphoto();
  }
  get designLogo(): string {
    return this.Data.token?.getCustomer()?.getLogo();
  }
  get designMainTextColor(): string {
    if (!this.designMainBackground) { return 'black'; }
    return this.Data.token?.getCustomer()?.getMenuforegroundcolor();
  }
  get designMainBackground(): boolean {
    return this.Data.token?.getCustomer()?.getMenubackground();
  }
  get designMainBackgroundColor(): string {
    if (!this.designMainBackground) { return ''; }
    const rgb = this.getRGB(this.Data.token?.getCustomer()?.getMenubackgroundcolor());

    return 'rgba(' + rgb.concat((this.designMainBackgroundOpacity) / 100).join(',') + ')';
  }
  get designMainBackgroundOpacity(): number {
    return this.Data.token?.getCustomer()?.getMenubackgroundopacity() || 100;
  }
  get designToolbarBackgroundColor(): string {
    return this.Data.token?.getCustomer()?.getColor();
  }
  get designToolbarTextColor(): string {
    return this.checkColorDarkness(this.Data.token?.getCustomer()?.getColor()) ? 'white' : 'black';
  }
  get dateFormat(): string {
    if (this.Data.lg === 'fr') {
      return 'DD.MM.YYYY';
    }

    return 'DD/MM/YYYY';
  }
  get userApp(): any {
    return +communityAppType;
  }
  /**
   * reset flag
   *
   * - last payment
   */
  clearTemporary() {
    this.clearLastPayment();
  }
  clearLastPayment() {
    this.storageLib.set('pay-id', '');
  }
  clearLastMealPayment() {
    this.storageLib.set('meal-pay-id', '');
  }
  get mealID() {
    let mid = this.storageLib.get('meal-id') || '';
    if (mid === '') {
        mid = UserLibService.newGuid();
        this.storageLib.set('meal-id', mid);
    }
    return mid;
  }

  savememtypes(types: MemberType[]) {
    this.storageLib.set(
      'mts', this.convLib.ToStorages(types)
    );
  }

  getmembertypes(callback: (mems: MemberType[])=>void) {
     this.convLib.FromStorages(
      this.storageLib.get("mts"), callback);
  }
}
