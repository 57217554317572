import { Component, OnInit, OnDestroy } from '@angular/core';
import { Member, Title, PoloSize, Country, MemberBusinessCard, MemberBusinessCardSettings } from 'src/app/libs/proto/commUnity_pb';
import { Subscription } from 'rxjs';
import { GrpcMemberLibService } from 'src/app/service/grpc/member/grpc-member-lib.service';
import { TranslateService } from '@ngx-translate/core';
import { UserLibService } from 'src/app/service/user/user-lib.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { GrpcUserService } from 'src/app/service/grpc/user/grpc-user.service';
import { desktopMode } from '../../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';
import { MemberToken, ScanMemberBusinessCardRequest } from 'src/app/libs/proto/mobile_pb';
import { MemberBusinessCardComponentBase } from './business-card.component';

@Component({
  selector: 'app-business-card',
  templateUrl: './business-card.component.html',
  styleUrls: ['./business-card.component.sass']
})
export class MemberBusinessCardVaidComponent extends MemberBusinessCardComponentBase implements OnInit {
  lg = '';

  constructor(
    private grpcMemberLib: GrpcMemberLibService,
    private translate: TranslateService,
    private userLib: UserLibService,
    private actRoute: ActivatedRoute,
    private safurl: DomSanitizer,
    private detector: DeviceLibService,
  ) {
    super();    
    this.noback = "Y";
    this.showvalid = true;
    this.showqr = false;
    this.isflip = true;
    this.datavalid = false;
    this.loading = true;
    
    // set default
    this.memberbusiness = new MemberBusinessCard();
    this.memberbusiness.setFirstname("invalid")
    this.memberbusiness.setLastname("invalid")
    this.memberbusiness.setPaidok(false)
    this.memberbusiness.setRenewon(moment().unix())
    let mbset = new MemberBusinessCardSettings();
    mbset.setShowfirstname(true);
    mbset.setShowlastname(true);
    mbset.setShowtitle(true);
    mbset.setShowrenewon(true);
    mbset.setShowpaidok(true);
    this.memberbusiness.setSettings(mbset);
  }

  ngOnInit(): void {
    const thise = this;
    this.actRoute.paramMap.subscribe( p => {
    var req = new ScanMemberBusinessCardRequest()
    req.setUid(p.get('token'))

    this.isLandscape = this.detector.orientation === 'landscape';
    let def = this.detector.lang(location.href);  

    thise.grpcMemberLib.scanMemberBusinessCard({
      Offline: false,
      call: {
        req: req,
      }
    }).then( ns => {
      if (ns && ns.length > 0) {
        thise.memberbusiness = ns[0];
        thise.menuType = ns[0].getMenutype();
    
        this.lg = this.detector.verfiyLang(ns[0].getLg());

        // showvalid = true when get data
        thise.datavalid = thise.memberbusiness?.getSettings().getVerifyqrcode();
        // validate time
        // issue date + 5 minutes < now = expire     
        thise.valid = thise.datavalid && 
                      thise.memberbusiness?.getIss() + (5 * 60 * 60) >= moment().unix() && 
                      ((thise.memberbusiness?.getRenewon() > 0 && thise.memberbusiness?.getRenewon() >= parseInt(moment().format('YYYYMMDD'))) 
                      || thise.memberbusiness?.getRenewon() == 0) &&
                      thise.memberbusiness?.getPaidok();
      }
    }).finally(() => {
      thise.translate.use( this.lg || def );     
      thise.loading = false; 
    });
  });
}

}
