import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { StorageLibService } from '../../service/storage/storage-lib.service';
import { Router } from '@angular/router';
import { UserLibService } from '../../service/user/user-lib.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogServiceService } from '../../service/dialog/dialog-service.service';

import { FirebaseService } from '../../service/firebase/firebase.service';
import { BehaviorSubject, timer, Subscription } from 'rxjs';
import { take } from 'rxjs/internal/operators/take';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { communityAppBeta } from '../../config/beta';
import { GrpcAlertLibService } from '../../service/grpc/alert/grpc-alert-lib.service';
import { GrpcAdsLibService } from '../../service/grpc/ads/grpc-ads-lib.service';
import { MenuName, Ad, CustomizedMenu, DesktopMenuType } from '../../libs/proto/commUnity_pb';
import * as moment from 'moment';
import { GrpcShopLibService } from '../../service/grpc/shop/grpc-shop-lib.service';
import { ShopSettingsConversionService } from '../../service/conversion/shop-settings/shop-settings-conversion.service';
import { CartConversionService } from '../../service/conversion/cart/cart-conversion.service';
import { GrpcLinksLibService } from '../../service/grpc/links/grpc-links-lib.service';
import { GrpcMenuLibService } from '../../service/grpc/menu/grpc-menu-lib.service';
import { Platform } from '@angular/cdk/platform';
import { desktopMode } from '../../config/type';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GrpcRestaurantLibService } from '../../service/grpc/restaurant/grpc-resto-lib.service';
import { RestaurantMealConversionService } from '../../service/conversion/restaurant/resto-meal-conversion.service';
import { GrpcTopicLibService } from 'src/app/service/grpc/topic/grpc-topic-lib.service';
import { GrpcPostLibService } from 'src/app/service/grpc/post/grpc-post-lib.service';
import { GrpcStreamLibService } from '../../service/grpc/stream/grpc-stream-lib.service';
import { GrpcCPSLibService } from 'src/app/service/grpc/booking/grpc-cps-lib.service';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';
import { of, Subject } from 'rxjs';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;

  askPushPermission: boolean;
  hasAlerts: boolean;
  showAlert: boolean;
  showAd?: Ad;
  hasLink = false;
  hasLink2 = false;
  hasLink3 = false;
  hasLink4 = false;

  hasStream = false;
  hasStream2 = false;
  hasStream3 = false;
  hasStream4 = false;
  hasCPSURL = false;
  hasGolfClass = false;

  cpsurl = '';
  cpsref = 0;
  cpsttl = 0;

  menus$: Promise<CustomizedMenu[]>;
  menuType = 0;
  menulen = 0;

  MenuFromGRPC = MenuName;
  show_member = false;

  constructor(
    private route: Router,
    private storageLib: StorageLibService,
    private userLib: UserLibService,
    private alertLib: DialogServiceService,
    private translate: TranslateService,
    private fcmLib: FirebaseService,
    private grpcLib: GrpcAlertLibService,
    private grpcAdsLib: GrpcAdsLibService,
    private grpcShopLib: GrpcShopLibService,
    private grpcRestoLib: GrpcRestaurantLibService,
    private grpcLinkLib: GrpcLinksLibService,
    private cartConvLib: CartConversionService,
    private mealConvLib: RestaurantMealConversionService,
    private grpcMenuLib: GrpcMenuLibService,
    private grpcTopicLib: GrpcTopicLibService,
    private grpcPostLib: GrpcPostLibService,
    private detector: DeviceLibService,
    private grpcStreamLib: GrpcStreamLibService,
    private grpcCPSLib: GrpcCPSLibService,
  ) {
    userLib.setPageLanguage(translate);
  }

  ngOnInit(): void {
    // reset
    this.userLib.clearTemporary();
    if (this.storageLib.get('f-signout') === 'Y') {
      this.userLib.Data.signOut = true;
      this.storageLib.set('f-signout', '');
   }

    this.fcmLib.requestToken();
    this.fcmLib.subscribeMessage();

    this.getCustomizedMenus();
    this.getMenuAlias();
    this.getLinks();
    this.getLinks2();
    this.getLinks3();
    this.getLinks4();
    this.getAds();
    this.getAlerts();
    this.getCarts();
    this.getMeals();
    this.getForums();

    this.getStream();
    this.getStream2();
    this.getStream3();
    this.getStream4();

    this.getCPSURL();

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
       this.isLandscape = this.landscapeEvt.matches;
       this.menuType = this.getmenuType();
    });

    // check member menu
    this.userLib.getmembertypes( (types) => {
      // show member?
      var showmem = (this.userLib.Data.token?.getCustomer().getShowmember() ?? false);
      // if private ?
      if (this.userLib.Data.token?.getProfile().getPrivate() || false) showmem = false;
      
      // all user type
      const usertypes = this.userLib.Data.token?.getProfile()?.getMembertypesList() ?? [];
      // if guest
      const isguest = (types ?? []).filter((e)=> usertypes.indexOf(e.getId()) >= 0 && e.getSignupdefault() )
      
      // all items are guest
      if (isguest.length == usertypes.length) {
        if (this.userLib.Data.token?.getCustomer().getHidemenumembersforguests() || false) {
          showmem = false;
        }
      }
      this.show_member = showmem;
    })
  }
  private getCustomizedMenus(){
    if (!(this.userLib.Data.token?.getCustomer().getShowcustomizedmenu() ?? false)) { return; }

    this.menus$ = this.grpcMenuLib.getCustomizedMenus({
      Offline: this.storageLib.cache.customMenu || false,
    }).then( mm => {
      this.menulen = mm?.length || 0;
      return of(mm).toPromise();
    });
  }
  private getMenuAlias(){
    this.grpcMenuLib.getMenuAlias({
      Offline: true,
    }).then( v => {
      this.userLib.Data.menuAlias = v;
    });
  }
  private getLinks(){
    if (!(this.userLib.Data.token?.getCustomer().getShowlinks() ?? false)) { return; }

    this.grpcLinkLib.getLinks({
      Offline: this.storageLib.cache.links || false,
    }).then( ll => {
      this.hasLink = ll && ll.length > 0;
    });
  }

  private getLinks2(){
    if (!(this.userLib.Data.token?.getCustomer().getShowlink2() ?? false)) { return; }

    this.grpcLinkLib.getLinks2({
      Offline: this.storageLib.cache.links2 || false,
    }).then( ll => {
      this.hasLink2 = ll && ll.length > 0;
    });
  }

  private getLinks3(){
    if (!(this.userLib.Data.token?.getCustomer().getShowlink3() ?? false)) { return; }

    this.grpcLinkLib.getLinks3({
      Offline: this.storageLib.cache.links3 || false,
    }).then( ll => {
      this.hasLink3 = ll && ll.length > 0;
    });
  }

  private getLinks4(){
    if (!(this.userLib.Data.token?.getCustomer().getShowlink4() ?? false)) { return; }

    this.grpcLinkLib.getLinks4({
      Offline: this.storageLib.cache.links4 || false,
    }).then( ll => {
      this.hasLink4 = ll && ll.length > 0;
    });
  }
  private getStream(){
    if (!(this.userLib.Data.token?.getCustomer().getShowstreampwa() ?? false)) { return; }

    this.grpcStreamLib.getStream({
      Offline: this.storageLib.cache.stream || false,
    }).then( ll => {
      this.hasStream = ll && ll.length > 0;
    });
  }

  private getStream2(){
    if (!(this.userLib.Data.token?.getCustomer().getShowstream2pwa() ?? false)) { return; }

    this.grpcStreamLib.getStream2({
      Offline: this.storageLib.cache.stream2 || false,
    }).then( ll => {
      this.hasStream2 = ll && ll.length > 0;
    });
  }

  private getStream3(){
    if (!(this.userLib.Data.token?.getCustomer().getShowstream3pwa() ?? false)) { return; }

    this.grpcStreamLib.getStream3({
      Offline: this.storageLib.cache.stream3 || false,
    }).then( ll => {
      this.hasStream3 = ll && ll.length > 0;
    });
  }

  private getStream4(){
    if (!(this.userLib.Data.token?.getCustomer().getShowstream4pwa() ?? false)) { return; }

    this.grpcStreamLib.getStream4({
      Offline: this.storageLib.cache.stream4 || false,
    }).then( ll => {
      this.hasStream4 = ll && ll.length > 0;
    });
  }

  private getCPSURL(){
    if (!(this.userLib.Data.token?.getCustomer().getShowcps() ?? false)) { return; }

    this.grpcCPSLib.getCPSURL({
      Offline: this.storageLib.cache.cpsurl || false,
    }).then( ll => {
      this.hasCPSURL = ll && ll.length > 0;
      if (this.hasCPSURL) {
        this.cpsurl = ll[0].getUrl();
        this.cpsttl = ll[0].getTtl();
        this.cpsref = moment().unix() + this.cpsttl;

        if (this.cpsttl > 0) {
          setTimeout(() => {
            this.reloadCPS();
          }, this.cpsttl * 1000);
        }
      }
    });
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: FocusEvent): void {
    this.reloadCPS();
  }

  private reloadCPS() {
    if (this.cpsttl === 0) { return; }
    if (this.cpsref > moment().unix()) { return; }

    this.getCPSURL();
  }

  private getCarts() {
    if (!(this.userLib.Data.token?.getCustomer().getShowshoppwa() ?? false)) { return; }

    this.grpcShopLib.getCart().then( c => {

      this.storageLib.set('cart-badge', c.getNbitems());
      this.storageLib.set('cart',
        this.cartConvLib.ToStorage( c ));
    });
  }
  private getForums() {
    if (!(this.userLib.Data.token?.getCustomer().getShowforum() ?? false)) { return; }

    this.grpcPostLib.getPosts().then( p => {
          this.storageLib.set('forum-badge', p ? p.length : 0);
    });
  }
  private getMeals() {
    if (!(this.userLib.Data.token?.getCustomer().getShowrestaurant() ?? false)) { return; }

    this.grpcRestoLib.getMeal({ call: { req: this.userLib.mealID }}).then( c => {

      this.storageLib.set('meal-badge', c.getNbitems());
      this.storageLib.set('meal',
        this.mealConvLib.ToStorage( c ));
    });
  }
  private getAds() {
    if (!(this.userLib.Data.token?.getCustomer().getShowad() ?? false)) { return; }

    const t = this.storageLib.get('show-ad') as number;
    if (t && t > +moment().format('YYYYMMDD')) {
        return;
    }

    this.grpcAdsLib.getAds().then( ads => {

      if (ads && ads.length > 0) {
          const rnd = Math.floor(Math.random() * ads.length);
          this.showAd = ads[rnd];
          this.storageLib.set('show-ad', +moment().add(1, 'day').format('YYYYMMDD'));
      }
    });
  }
  private getAlerts() {
    this.grpcLib.getAlert({

    }).then( als => {
      this.hasAlerts = als && als.length > 0;
    });
  }

  showRequest() {
    this.askPushPermission = true;
  }

  rightButton(){
    if (this.isGuest) {
        this.userLib.clear();
        this.userLib.Data.signOut = true;
        this.route.navigateByUrl('/login');
        return;
    }

    this.translate.get([
      'home.confirm_disconnect',
      'home.Disconnect',
    ]).toPromise().then( t => {

      this.alertLib.confirm(t['home.confirm_disconnect'], (r) => {

        if (r.no) { return; }

        this.doSignOut();

      }, t['home.Disconnect']);

    });
  }

  private doSignOut(){
    let scus = this.storageLib.get('s-cus');
    this.userLib.clear();
    this.storageLib.set('login-' + (this.userLib.Data.selectedCustomer?.getId() || ''), null );
    this.userLib.Data.signOut = true;
    this.storageLib.set('f-signout', 'Y');
    this.userLib.Data.producstPage = undefined;
    this.storageLib.set('s-cus', scus);

    // fixed when safari, avoid get session state
    // will get other behavior, after user logout they will autosignin as guest.
    // 2021-03-09 KPO customer prefer to have the same in android and ios
    location.reload();
  }

  account() {
    this.route.navigateByUrl('/account');
  }

  get isGuest(): boolean {
    const p = this.userLib.Data.token?.getProfile();
    return (p && p.getIsguest()) || false;
  }

  get showAccount(): boolean {
    return !this.isGuest &&
           (this.customer && this.customer.getShowmyaccount() || false);
  }

  get backgroundImage() {
    return this.userLib.designMainBg;
  }

  get logo() {
    return this.userLib.designLogo;
  }

  get groupLogo() {
    return this.userLib.Data.selectedGroup?.getLogo();
  }

  get textColor() {
    return this.userLib.designMainTextColor;
  }
  get customer() {
    return this.userLib.Data.token?.getCustomer();
  }
  get backgroundSize() {
    if (!this.detector.isMobile()) {
      return (this.customer && this.customer.getBackgrounddesktopphotoskretch() || false) ? 'cover' : '';
    }
    return (this.customer && this.customer.getBackgroundphotoskretch() || false) ? 'cover' : '';
  }
  get backgroundColor() {
    return this.userLib.designMainBackgroundColor;
  }

  get customerName() {
    if (!(this.customer && this.customer.getShowcustomername()) || false) { return; }

    return this.userLib.Data.token?.getCustomer().getName();
  }

  get cartBadge() {
    return this.storageLib.get('cart-badge');
  }
  get forumBadge() {
    return this.storageLib.get('forum-badge');
  }
  get hasSearch() {
    return (this.customer && this.customer.getShowsearch()) || false;
  }

  get isBeta() {
    return communityAppBeta;
  }
  doAlert() {
    this.showAlert = true;
  }
  closeAlert() {
    this.showAlert = false;
  }
  toGroup() {
    this.userLib.clear();
    this.userLib.Data.selectedGroup = undefined;
    this.userLib.Data.selectedCustomer = undefined;
    this.storageLib.set('s-cus', '');
    this.route.navigateByUrl('/login');
  }

  get show_links() {
    return this.hasLink;
  }
  get show_event() {
    return this.userLib.Data.token?.getCustomer().getShowevent() ?? false;
  }
  get show_news() {
    return this.userLib.Data.token?.getCustomer().getShownews() ?? false;
  }
  get show_shop() {
    return this.userLib.Data.token?.getCustomer().getShowshoppwa() ?? false;
  }
  get show_shopprofile() {
    return this.userLib.Data.token?.getCustomer().getShowshopprofile() ?? false;
  }
  get show_benefit() {
    return this.userLib.Data.token?.getCustomer().getShowbenefit() ?? false;
  }
  get show_album() {
    return this.userLib.Data.token?.getCustomer().getShowalbum() ?? false;
  }
  get show_leader() {
    return this.userLib.Data.token?.getCustomer().getShowleaderboard() ?? false;
  }
  get show_competition() {
    return this.userLib.Data.token?.getCustomer().getShowcompetition() ?? false;
  }
  get show_place() {
    return this.userLib.Data.token?.getCustomer().getShowplacemenu() ?? false;
  }
  get show_forum() {
    return this.userLib.Data.token?.getCustomer().getShowforum() ?? false;
  }
  get show_golf_teaching_class() {
    return this.userLib.Data.token?.getCustomer().getShowgolfteachingclass() ?? false;
  }
  get show_room_reservation() {
    return this.userLib.Data.token?.getCustomer().getShowhotix() ?? false;
  }

  closeAds() {
    this.showAd = undefined;
  }
  get menuAlias() {
    return this.userLib.Data.menuAlias;
  }
  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
  getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer().getMobilemenutype();
  }
  get show_restaurant() {
    return this.userLib.Data.token?.getCustomer().getShowrestaurant() ?? false;
  }
  get mealBadge() {
    return '';
  }
  open_restaurant() {
    this.storageLib.set('table-id', '');
    this.route.navigateByUrl('/resto');
  }
  get show_news2() {
    return this.userLib.Data.token?.getCustomer().getShownews2() ?? false;
  }
  get show_news3() {
    return this.userLib.Data.token?.getCustomer().getShownews3() ?? false;
  }
  get show_news4() {
    return this.userLib.Data.token?.getCustomer().getShownews4() ?? false;
  }
  get show_links2() {
    return this.hasLink2;
  }
  get show_links3() {
    return this.hasLink3;
  }
  get show_links4() {
    return this.hasLink4;
  }
  get show_stream() {
    return this.hasStream;
  }
  get show_stream2() {
    return this.hasStream2;
  }
  get show_stream3() {
    return this.hasStream3;
  }
  get show_stream4() {
    return this.hasStream4;
  }
  get show_cpsurl() {
    return this.hasCPSURL;
  }
  get show_document() {
    return this.userLib.Data.token?.getCustomer().getShowdocumentpwa() ?? false;
  }

  get menucount() {
    let c = 0;
    if (this.show_event) { c++; }
    if (this.show_news) { c++; }
    if (this.show_news2) { c++; }
    if (this.show_news3) { c++; }
    if (this.show_member) { c++; }
    if (this.show_benefit) { c++; }
    if (this.show_forum) { c++; }
    if (this.show_shop) { c++; }
    if (this.show_restaurant) { c++; }

    if (this.show_links) { c++; }
    if (this.show_links2) { c++; }
    if (this.show_links3) { c++; }
    if (this.show_links4) { c++; }

    if (this.show_stream) { c++; }
    if (this.show_stream2) { c++; }
    if (this.show_stream3) { c++; }
    if (this.show_stream4) { c++; }

    if (this.show_place) { c++; }
    if (this.show_competition) { c++; }
    if (this.show_leader) { c++; }
    if (this.show_album) { c++; }

    if (this.show_shopprofile) { c++; }
    c += this.menulen;
    if (this.show_cpsurl) { c++; }
    return c;
  }
}
