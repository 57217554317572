import { Component, OnInit, AfterViewInit, AfterContentChecked } from '@angular/core';
import { Router } from '@angular/router';
import { UserLibService } from '../../service/user/user-lib.service';
import { GrpcLibService } from '../../service/grpc/grpc-lib.service';
import { of } from 'rxjs';
import { StorageLibService } from '../../service/storage/storage-lib.service';
import { GrpcShopLibService } from '../../service/grpc/shop/grpc-shop-lib.service';
import { desktopMode } from '../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';
import { Document as PbDocument } from 'src/app/libs/proto/commUnity_pb';
import { GrpcDocumentLibService } from 'src/app/service/grpc/document/grpc-document-lib.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.sass']
})
export class DocumentsComponent implements OnInit, AfterContentChecked {

  documents$: Promise<PbDocument[]>;
  category
  defaultCollapse: string;
  onceScroll = false;

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;

  menuType = 0;

  constructor(
    private route: Router,
    private grpcDocumentLib: GrpcDocumentLibService,
    private userLib: UserLibService,
    private storeLib: StorageLibService,
    private detector: DeviceLibService,
  ) { }
  
  isCollapsed(c: string) {
    if (this.defaultCollapse) { return this.defaultCollapse === 'y'; }
    return this.userLib.Data.documentsPage?.collapsed?.indexOf(c) >= 0;
  }

  ngOnInit(): void {
    this.getdocuments();

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
      this.isLandscape = this.landscapeEvt.matches;
      this.menuType = this.getmenuType();
    });
  }

  checkVisible(elm: HTMLElement) {
    if (!elm) { return true; }
    const rect = elm.getBoundingClientRect();
    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  }

  private scrollToPrev() {
    const c = document.getElementById(this.userLib.Data.documentsPage?.id);
    if (this.checkVisible(c)) { return; }

    c?.scrollIntoView();
  }

  ngAfterContentChecked() {
    if (this.onceScroll) { return; }

    this.scrollToPrev();
  }

  private getdocuments() {
    if (this.storeLib.get('document-s')) {
        this.storeLib.set('document-s', null);
        this.storeLib.cache.document = false;
    }
    this.documents$ = this.grpcDocumentLib.getDocuments({
      Offline: this.storeLib.cache.document || false,
    }).then( pp => {
      if (this.defaultCollapse === 'y') {
        for (const p of pp) {
          this.expanded(p.getCategoryid(), 2);
        }
        this.defaultCollapse = undefined;
      }
      return of(pp).toPromise();
    }).catch( (e: Error) => {
      if (e.message === GrpcLibService.ERR_SIGIN) {

        this.userLib.clear();
        this.route.navigateByUrl('/login');
        return;
      }
      // return to complete
      return of([]).toPromise();
    });
  }

  open(url: string){
    window.open(url);
  }

  clearScrollFlag() {
    this.onceScroll = true;
  }

  expanded(id: string, t: number){
    if (t !== 2) { this.onceScroll = true; }
    if (this.userLib.Data.documentsPage === undefined) {
      this.userLib.Data.documentsPage = {};
    }
    if (this.userLib.Data.documentsPage.collapsed === undefined ){
      this.userLib.Data.documentsPage.collapsed = [];
    }
    const ii = this.userLib.Data.documentsPage?.collapsed.indexOf(id);
    if (t === 1) {
       this.userLib.Data.documentsPage?.collapsed.splice(ii, 1);
    } else {
      if (ii === -1) {
        this.userLib.Data.documentsPage?.collapsed.push(
        id
        );
      }
    }
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer().getMobilemenutype();
  }
 }
